<template>
  <div>
    <b-card no-body>
      <b-tabs v-model="previewTabIndex" card>
        <b-tab title="Royalty Report Preview" active>
          <b-card-text
            ><section class="invoice-add-wrapper">
              <b-row class="invoice-add">
                <!-- Col: Left (Invoice Container) -->
                <b-col cols="12" xl="12" md="12">
                  <b-embed
                    v-if="previewPdf"
                    type="iframe"
                    aspect="16by9"
                    :src="previewPdf"
                    allowfullscreen
                  ></b-embed>
                  <!-- <vue-pdf-embed
                    ref="myPdfComponent"
                    v-if="previewPdf"
                    :source="previewPdf"
                  /> -->
                </b-col>
              </b-row>

              <invoice-sidebar-send-invoice
                :id="this.royalty_report_id"
                type="RoyaltyInvEmail"
              /></section
          ></b-card-text>
        </b-tab>
        <b-tab title="Revenue Included">
          <div v-if="previewTabIndex == 1 && this.reportData">
            <royalty-included-revenue :reportDataItem="this.reportData" />
          </div>
        </b-tab>
        <b-tab title="Expenses Included">
          <div v-if="previewTabIndex == 2 && this.reportData">
            <included-expense-royalty :reportDataItem="this.reportData" />
          </div>
        </b-tab>
        <b-tab title="MG">
          <div v-if="previewTabIndex == 3 && this.reportData">
            <included-mg-royalty :reportDataItem="this.reportData" />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import RoyaltyIncludedRevenue from "./IncludedRevenueRoyalty.vue";
import IncludedExpenseRoyalty from "./IncludedExpenseRoyalty.vue";
import IncludedMgRoyalty from "./IncludedMgRoyalty.vue";

import { GetRoyaltyReportById } from "@/apiServices/RevenueServies";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BTableLite,
  BTabs,
  BTab,
  BEmbed,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

import InvoiceSidebarSendInvoice from "../Revenue/InvoiceSidebarSendInvoice.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    BTabs,
    BTab,
    flatPickr,
    vSelect,
    Logo,
    BTableLite,

    ToastificationContentVue,
    InvoiceSidebarSendInvoice,
    RoyaltyIncludedRevenue,
    IncludedExpenseRoyalty,
    IncludedMgRoyalty,
    BEmbed,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  props: {
    selected_royalty_report_id: {
      type: Number,
    },
  },

  data() {
    return {
      BASE_URL_UPLOAD: process.env.VUE_APP_FILESURL + "/",
      royalty_report_id: null,

      previewPdf: null,
      previewTabIndex: 0,

      //old
      moment: moment,
      reportData: [],
    };
  },

  mounted() {},

  beforeMount() {
    // this.royalty_report_id = this.$route.params.royalty_report_id;
    this.royalty_report_id = this.selected_royalty_report_id;
    this.GetRoyaltyReportById();
  },
  methods: {
    printPdf() {},

    async GetRoyaltyReportById() {
      try {
        let payload = {
          royalty_report_id: this.royalty_report_id,
        };
        const response = await GetRoyaltyReportById(payload);
        if (response.data.status) {
          this.reportData = response.data.data;
          this.previewPdf =
            this.BASE_URL_UPLOAD + this.reportData.royalty_report_document;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
  },
  created() {},
  destroyed() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
